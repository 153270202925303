import React from 'react';
import { Container } from 'reactstrap';
import NavMenu from '../NavMenu/NavMenu';
import Footer from '../Footer/Footer';
import FloatingContactIcons from '../FloatingContactIcons/FloatingContactIcons';
import Img from '../../Images/pattern-9.png';

export default props => (
    <div style={{ backgroundImage: 'url(' + Img +')' }}>
    <NavMenu />
      {props.children}
    <Footer/>
    <FloatingContactIcons />
  </div>
);
