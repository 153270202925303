import React, { useRef} from 'react';
import { connect } from 'react-redux';
import BaseButton from '../BaseButton/BaseButton';
import defaultImg from '../../Images/placeholder_img.png';
import './featuredCard.css';


const FeaturedCard = props => {
    const { id, title, price, imageUrl, isDiscount, fkCategory, friendlyUrl } = props.cardData;
    const LargeImgInput = useRef(null);
    const openImgOverlay = () => {
        LargeImgInput.current.classList.add("show-img");
    }

    const closeImgOverlay = () => {
        LargeImgInput.current.classList.remove("show-img");
    }

    return (
        <div className="featuredCard"  style={{ backgroundImage: `url(${imageUrl.length == 0 ? defaultImg : imageUrl })` }}>
            {isDiscount == "True" ? ( <div className="featuredCard-indirim"> indirimli </div>) : null}
            <div className="featuredCard-overlay">
                <button aria-label="Hızlı Bakış" className="mybtn btn-incele" onClick={openImgOverlay} title="Hızlı Bakış">  <i className="fa fa-search" aria-hidden="true"></i> </button>
                <BaseButton url={`/productdetail/${friendlyUrl}`} title={'<i class="fa fa-external-link" aria-hidden="true"></i>'} />
            </div>
            <div className="featuredCard-stats">
                <span className="featuredCard-stats--title">
                    {title}
                </span>
                <span className="featuredCard-stats--price">{price}</span>
            </div>
            <div ref={LargeImgInput} className="featuredCard-overlay--img">
                <button onClick={closeImgOverlay}><i className="fa fa-times" aria-hidden="true"></i></button>
                <div className="overlay-img--box">
                    <img onClick={() => { return }} src={imageUrl} alt={title} />
                </div>
            </div>
        </div>
    );
}





export default connect()(FeaturedCard);