import React from 'react';
import { connect } from 'react-redux';
import './pagination.css';

const Pagination = ({ itemsPerPage, totalItems, paginate }) => {

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination-wrapper">
            <nav>
                <ul className="pagination">
                    {
                        pageNumbers.map(number => (
                            <li key={number} className="page-item">
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    paginate(number)
                                }} href="" className="page-link">
                                    {number}
                                </a>
                            </li>
                        ))
                    }
                </ul>
            </nav>
        </div>
    );


}


export default connect()(Pagination);
