
import React, { useState, useEffect }   from 'react';
import { connect } from 'react-redux';
import { Viewer, Worker } from '@react-pdf-viewer/core';

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';




import pdfFile from '../Document/mastering_react.pdf';

const PDFDocument = props => {
 
    const [defaultPdfFile, setDefaultPdfFile] = useState(pdfFile);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();


    const _data = props.catalog;

    const catalogUrlExt = "http://www.yapimarketpanel.somee.com/ProductImages/Buyuk/53b853c7-2bf6-4be7-b1f6-6d1b5df2b8cf.pdf";

    return (
       <>
            {
                _data && _data.map((dt, idx) =>

                    //    <Worker key={idx} workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js" >
                    //    <div className="document-height" style={{ height: '750px' }}>
                    //        <Viewer
                    //                fileUrl={catalogUrlExt}
                    //            plugins={[
                    //                defaultLayoutPluginInstance,
                    //            ]}
                    //        />
                    //    </div>
                    //</Worker>


                    <iframe key={idx} src={dt}
                        style={{ height: '750px', width: '100%' }} frameBorder="0"></iframe>
                
            )
        }
  
    </>
  
    );

};


export default connect()(PDFDocument);