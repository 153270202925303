import React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import './PageBreadCrumb.css';
import { Link } from 'react-router-dom';

const PageBreadCrumb = props => {

    const { bgImageUrl, pageTitle, linkList } = props.BreadcrumbData;


    return (
        <div className="Pagebreadcrumb-container" style={{ backgroundImage: `url(${bgImageUrl})` }}>
            <div className="Pagebreadcrumb-content mycontainer">
                
                <div className="breadcrumb-box">
                    <h1> {pageTitle} </h1>
                    <Breadcrumb tag="nav" listTag="div">
                        {linkList.map((link, idx) => {
                            if (link.length == idx +1) {
                                    return (<BreadcrumbItem key={idx} active tag="span">  {link.title}</BreadcrumbItem>)
                                } else {
                                return (<BreadcrumbItem key={idx} tag="span"> <Link to={link.url}>   {link.title} </Link> </BreadcrumbItem>)
                                } 
                            })
                        }
                    </Breadcrumb>
                </div>

            </div>
        </div>
    )

};

      




export default connect()(PageBreadCrumb);