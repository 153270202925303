const requestProductCategoryType = 'REQUEST_PRODUCT_CATEGORY';
const receiveProductCategoryType = 'RECEIVE_PRODUCT_CATEGORY';
const initialState = { categories: [], isLoading: false };




export const actionCreators = {
 
    requestProductCategories: id => async (dispatch, getState) => {
       // console.log(id);

       //if (id === getState().productCategories[0].id) {
       //     return;
       // }

       dispatch({ type: requestProductCategoryType, id });

        const url = "api/getproductsbycategoryid?id=" + id;
        const response = await fetch(url);
        const categories = await response.json();
      
        dispatch({ type: receiveProductCategoryType, id, categories });
    }
};


export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestProductCategoryType) {
        return {
            ...state,
            id: action.id,
            isLoading: true
        };
    }

    if (action.type === receiveProductCategoryType) {
        return {
            ...state,
            id: action.id,
            categories: action.categories,
            isLoading: false
        };
    }

    return state;
};






