import React from 'react';
import { connect } from 'react-redux';
import './loader.css';


const Loader = props => (
    <div className={`loader-wrapper ${props.isLoading === true ? 'on-loading' :  ''}  `}>
        <div className="loader-content">

            <i className="fa fa-spinner fa-pulse fa-3x fa-fw" aria-hidden="true"></i>
        </div>
    </div>
);

export default connect()(Loader);
