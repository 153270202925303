import React from 'react';
import { connect } from 'react-redux';
import './SocialShare.css';

import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,

} from "react-share";

const SocialShare = props => {
    const {
        url,
        title,
        size,
    } = props.shareData;

    return (
        <div className="social-share--container">
            <FacebookShareButton
                quote={title}
                url={url}
                windowWidth={300} windowHeight={300}
            >
                <FacebookIcon
                    size={size}
                />
            </FacebookShareButton>

            <TwitterShareButton
                title={title}
                url={url}
                windowWidth={750}
                windowHeight={600}
            >
                <TwitterIcon
                    size={size}
                />
            </TwitterShareButton>

            {/*<LinkedinShareButton*/}
            {/*    title={title}*/}
            {/*    url={url}*/}
            {/*    windowWidth={750}*/}
            {/*    windowHeight={600}*/}
            {/*>*/}
            {/*    <LinkedinIcon*/}
            {/*        size={size}*/}

            {/*    />*/}
            {/*</LinkedinShareButton>*/}

            <WhatsappShareButton
                url={url}
                title={title}
                separator=":: "
            >
            <WhatsappIcon size={size} />
            </WhatsappShareButton>

        </div>
    )


}





export default connect()(SocialShare);
