
const requestProductDropdownType = 'REQUEST_PRODUCT_DROPDOWN';
const receiveProductDropdownType = 'RECEIVE_PRODUCT_DROPDOWN';
const initialState = { dropdowns: [], isLoading: false };




export const actionCreators = {
    requestProductDropDowns: () => async (dispatch, getState) => {

        //if (getState().dropDowns.length > 0) {
        //    return;
        //}

        dispatch({ type: requestProductDropdownType });
        const url = "api/getproductdropdownlinks";
        const response = await fetch(url);
        const dropdowns = await response.json();
        dispatch({ type: receiveProductDropdownType, dropdowns });
    }
};




export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestProductDropdownType) {
        return {
            ...state,
            isLoading: true
        };
    }

    if (action.type === receiveProductDropdownType) {
        return {
            ...state,
            dropdowns: action.dropdowns,
            isLoading: false
        };
    }

    return state;
};





