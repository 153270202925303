import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BaseButton from '../../components/BaseButton/BaseButton';
import './notfound.css';

import Img from '../../Images/demirel_tesisat-removebg-preview.png';

const NotFound = () => {

    useEffect(() => {
        document.title = "Not Found | Demireller Yapı ve Tarım";
    },[])


    return (
        <div className="notfound-wrapper">

            <div className="notfound-container">
                <img src={Img} alt="notfound" />
                <p> Ooops, Aradığınız sayfa bulunamadı</p>
                <BaseButton url="/" title="Anasayfa Dön" />
            </div>

        </div>
    );

}





export default connect()(NotFound);
